import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";
import { setEmail, userLoggedIn, userLoggedOut, verifyOtp } from "./authSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "/users/login",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const {
            data: { data, token },
          } = await queryFulfilled;

          console.log({ user: data?.user, token: token });

          dispatch(userLoggedIn({ user: data?.user, token: token }));

          localStorage.setItem(
            "auth",
            JSON.stringify({ user: data?.user, token: token })
          );
        } catch (err) {
          // do nothing
        }
      },
    }),
    register: builder.mutation({
      query: (data) => ({
        url: "/users/signup",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          if (result.data.status === "success") {
            toast.success("Registered Successfully!");
            dispatch(verifyOtp(1));
            dispatch(setEmail(result?.data?.data?.user?.email));
          }
        } catch (err) {
          // do nothing
        }
      },
    }),
    verifyOtp: builder.mutation({
      query: (data) => ({
        url: "/users/verify/email",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          console.log(result);
          if (result.data.status === "success") {
            toast.success("OTP verify Successfully!");
            dispatch(verifyOtp(1));
            dispatch(setEmail(result?.data?.data?.user?.email));
          }
        } catch (err) {
          // do nothing
        }
      },
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "/users/forgotPassword",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          if (result.data.status === "success") {
            toast.success("Registered Successfully");
          }
          if (result.data.status === "fail") {
            toast.warning("Registered Fail", "success");
          }
        } catch (err) {
          // do nothing
        }
      },
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `/users/resetPassword/${data?.verifyToken}`,
        method: "PATCH",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          if (result.data.status === "success") {
            toast.success("Password Changed Successfully");
          }
          if (result.data.status === "fail") {
            toast.warning("Registered Fail", "success");
          }
        } catch (err) {
          // do nothing
        }
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/users/logout",
        method: "GET",
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          if (result.data.status === "success") {
            dispatch(userLoggedOut());
            localStorage.removeItem("auth");
          }
        } catch (err) {
          // do nothing
        }
      },
    }),
    deletUser: builder.query({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useVerifyOtpMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  useDeletUserQuery,
} = authApi;
