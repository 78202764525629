/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Button from "../components/Button";
import Header from "../components/Header";
import { useGetReportsQuery } from "../features/report/reportApi";
import redcheck from "../assets/redcheck.png";
import greencheck from "../assets/greencheck.png";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
// import Modal from "../components/Modal/Modal";
// import DeleteReport from "../components/DeleteReport";

export default function Dashboard() {
  const { data } = useGetReportsQuery();
  const { reports } = data?.data || {};
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // const [open, setOpen] = useState(false);
  useEffect(() => {
    if (user.role === "Employee") {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <Header />
      <div className="flex gap-5 px-2">
        <div className="hidden w-80 py-3 sm:block">
          <Button>Dashboard</Button>
        </div>

        <div className="flex flex-1 flex-col">
          <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full">
                  <thead className=" bg-[#2B78CB] capitalize ">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-4 text-left text-sm font-medium text-gray-900"
                      >
                        Employee name
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-4 text-left text-sm font-medium text-gray-900"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-left text-sm font-medium text-gray-900"
                      >
                        status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-left text-sm font-medium text-gray-900"
                      >
                        action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports?.map((report, id) => (
                      <tr
                        key={report._id}
                        className="my-2 rounded-lg border bg-gray-100"
                      >
                        <td className="whitespace-nowrap px-6 py-4 text-sm font-light text-gray-900">
                          {report?.observerName}
                        </td>

                        <td className="whitespace-nowrap px-6 py-4 text-sm font-light text-gray-900">
                          {moment(report.createdAt).format("D-MM-YYYY, h:mm A")}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm font-light text-gray-900">
                          {report.isReviewed ? (
                            <img src={greencheck} alt="" />
                          ) : (
                            <img src={redcheck} alt="" />
                          )}
                        </td>
                        <td className="flex gap-4 whitespace-nowrap px-6  py-4 text-sm font-semibold text-green-600">
                          <Link to={`/dashboard/${report._id}`}>View</Link>{" "}
                          {/* <span
                            onClick={() => setOpen(true)}
                            className=" cursor-pointer font-semibold text-red-600"
                          >
                            Delete
                          </span>
                          <Modal open={open} setOpen={setOpen}>
                            <DeleteReport setOpen={setOpen} id={report._id} />
                          </Modal> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
