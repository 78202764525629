import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  verifyOtp: 0,
  email: "",
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    userLoggedOut: (state, action) => {
      state.user = {};
      state.token = "";
    },
    verifyOtp: (state, action) => {
      state.verifyOtp = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

export const { userLoggedIn, userLoggedOut, verifyOtp, setEmail } =
  authSlice.actions;
export default authSlice.reducer;
