/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import FeedbackForm from "../components/FeedbackForm";
import Header from "../components/Header";
import Modal from "../components/Modal/Modal";
import { useGetSingleReportQuery } from "../features/report/reportApi";
import ReactToPrint from "react-to-print";

export default function ReportDetails() {
  const ref = useRef();
  const { id } = useParams();
  const { data } = useGetSingleReportQuery(id);
  const { report } = data?.data || {};
  const [open, setOpen] = React.useState(false);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.role === "Employee") {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <Header />
      <div className="container mb-5">
        <div className="flex items-center justify-end mt-8">
          
          <div className="w-32">
            {report?.isReviewed ? (
              <ReactToPrint
                trigger={() => (
                  <button className="rounded-lg bg-[#2B78CB] px-4 py-2 text-white">
                    Print
                  </button>
                )}
                content={() => ref.current}
              />
            ) : (
              <Button onClick={() => setOpen(true)}>Report</Button>
            )}
          </div>
          <Modal open={open} setOpen={setOpen}>
            <FeedbackForm setOpen={setOpen} id={id} />
          </Modal>
        </div>
        <div className="" ref={ref}>
          <div className="flex items-center px-5">
            <div className="flex-1 ">
              <h2 className="py-5 text-center font-sans text-4xl font-semibold">
                Near Miss Report
              </h2>
            </div>
            <p className="font-semibold">SOS-TEM-HS-008-A</p>
          </div>
        <div className="border-t-2 border-l-2 border-r-2 border-black m-3" >
          <div className="my-5 flex flex-col gap-4">
            <SingleData label="Department:" value={report?.department} />
            <SingleData label="Building/Work Area:" value={report?.workArea} />
            <SingleData
              label="Date and Time of Observation:"
              value={moment(report?.dateTime).format("MMMM Do YYYY, h:mm:ss a")}
            />
            <SingleData
              type={"array"}
              label="Type of Near Miss:"
              value={report?.typeNearMiss}
              desc={report?.typeNearMissDescribe}
            />
            <SingleData
              label="Type of Concern:"
              value={report?.typeConcern}
              desc={report?.typeConcernDescribe}
            />
            <SingleData
              type={"array"}
              label="Describe the potential incident/hazard/concern and possible outcome:"
              value={report?.potentialIncident}
            />
            <SingleData
              label="Were HSE procedures violated?"
              value={report?.isHseViolated ? "Yes" : "No"}
            />
            {/* <SingleData label="Photos of Work Area" value={report?.photo} /> */}

            <div className="pb-3 px-3 border-b-2 border-black">
              <h3 className="font-medium">{"Photos of Work Area"}</h3>
              <div className="flex w-full flex-wrap items-center justify-start gap-2">
                {report?.photo?.map((p) => (
                  <div className="" key={p}>
                    <img
                      src={"https://nodeapi.apps-sos.com/" + p}
                      alt=""
                      className="h-[213px] w-[213px] object-contain"
                    />
                  </div>
                ))}
              </div>
            </div>

            <SingleData label="Observer Name:" value={report?.observerName} />
            <SingleData
              label="Date Reported:"
              value={moment(report?.createdAt).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            />
          </div>
          {report?.feedback?.length > 0 ? (
            <div className="flex flex-col gap-4">
              <SingleData
                label="Recommendations/steps to take to prevent a similar incident:"
                value={report?.feedback[0]?.recommendation}
              />
              <SingleData
                label="Incident site inspection –Why was an unsafe act committed, or why was the unsafe condition present:"
                value={report?.feedback[0]?.incidentInspection}
              />
              <SingleData
                label="Corrective Action Implemented:"
                value={report?.feedback[0]?.correctiveAction}
              />

              <div className="pb-3 px-3 border-b-2 border-black">
                <h3 className="font-medium">{"Photos of Work Area after the corrective action:"}</h3>
                <div className="flex w-full flex-wrap items-center justify-start gap-2">
                  {report?.feedback[0]?.photo?.map((p) => (
                    <div className="" key={p}>
                      <img
                        src={"https://nodeapi.apps-sos.com/" + p}
                        alt=""
                        className="h-[213px] w-[213px] object-contain"
                      />
                    </div>
                  ))}
                </div>
              </div>
              {/* <SingleData
                label="Photos of Work Area after the corrective action:"
                value={report?.feedback[0]?.photoWorkArea}
              /> */}
              <SingleData
                label="Report Closed By:"
                value={report?.feedback[0]?.closedBy}
              />

              <SingleData
                label="Closed Date:"
                value={moment(report?.feedback[0]?.createdAt).format(
                  "MMM DD YYYY, h:mm:s A"
                )}
              />
            </div>
          ) : null}
        </div>
        </div>
      </div>
    </div>
  );
}

const SingleData = ({ label, value, type, desc }) => {
  return (
    <div className="border-b-2 border-black pb-3 px-3">
      {type === "array" ? (
        <>
          <h3 className="font-medium text-lg">{label}</h3>
          <ul className="my-2 ml-4">
            {value?.map((item, id) => (
              <li key={id}>{item}</li>
            ))}
          </ul>
        </>
      ) : (
        <>
          <h3 className="text-lg font-medium">
            {label} <br /> <span className="font-normal ml-4 capitalize">{value}</span>
          </h3>
          {desc && <p className="ml-4">{desc}</p>}
        </>
      )}
    </div>
  );
};
