import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { useFeedbackMutation } from "../features/report/reportApi";
import Alert from "./Alert/Alert";
import FormikControl from "./Forms/FormikControl";
import Button from "./Button";
import { useSelector } from "react-redux";
import moment from "moment";
import greenCheck from "../assets/greencheck.png";
import { UploadFile } from "./UploadFile";

export default function FeedbackForm({ id, setOpen }) {

  const [photo, setPhoto] = useState([]);
  const {
    user: { firstName, lastName },
  } = useSelector((state) => state.auth);
  const [feedbacks, { error }] = useFeedbackMutation();
  const initialValues = {
    type: "",
    recommendation: "",
    incidentInspection: "",
    correctiveAction: "",
    photo: "",
  };

  const validationSchema = Yup.object({
    type: Yup.string().required("is required!"),
    recommendation: Yup.string(),
    incidentInspection: Yup.string(),
    correctiveAction: Yup.string(),
    photo: Yup.string(),
  });

  const onSubmit = (values) => {
    feedbacks({
      ...values,
      photo,
      closedBy: firstName + " " + lastName,
      closedDate: Date.now(),
      report: id,
    })
      .unwrap()
      .then((res) => {
        console.log(res);
        setOpen(false);
        window.location.reload();
      });
  };

  const typeOptions = [
    { key: "Recomendation", value: "recommendation" },
    { key: "Close Report", value: "feedback" },
  ];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className=" mt-10 flex flex-col gap-5 rounded-[10px]">
            {error && (
              <Alert
                type="error"
                message={error?.message || error?.data?.message}
              />
            )}
            <div className="flex items-center justify-between rounded-sm bg-white py-5 px-3 shadow-md">
              <h6 className="font-semibold">Report Type:</h6>
              <div className="flex items-center">
                <Field name={"type"}>
                  {({ field }) => {
                    return typeOptions.map((option) => {
                      return (
                        <div className="" key={option.key}>
                          <label
                            htmlFor={option.value}
                            className="flex justify-start items-center cursor-pointer"
                          >
                            <input
                              className="w-fit mr-2"
                              type="radio"
                              id={option.value}
                              {...field}
                              value={option.value}
                              checked={field.value === option.value}
                            />

                            <p className="w-[140px]">{option.key}</p>
                          </label>
                        </div>
                      );
                    });
                  }}
                </Field>
              </div>
            </div>
            <FormikControl
              control="input"
              name="recommendation"
              label="Recommendations/steps to take to prevent a similar incident:"
            />
            <FormikControl
              control="input"
              type="text"
              name="incidentInspection"
              label="Incident site inspection –Why was an unsafe act committed, or why was the unsafe condition present:"
            />
            <FormikControl
              control="input"
              type="text"
              name="correctiveAction"
              label="Corrective Action Implemented:"
            />
            <div className="flex flex-col gap-3 rounded-sm bg-white py-5 px-3 shadow-md">
              <div className="flex items-center gap-3">
                <h3 className="text-gray-900 w-full mb-2 block text-base font-bold">Photos of Work Area after the corrective action:(At most 3)</h3>
              </div>
              <ul>
                {photo?.map((p) => (
                  <li key={p} className="flex items-center gap-2">
                    {" "}
                    <a
                      href={"https://nodeapi.apps-sos.com/" + p}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {p.split(".jpeg")[0]}
                    </a>
                    <span className="">
                      <img src={greenCheck} alt="" />
                    </span>
                  </li>
                ))}
              </ul>
              <UploadFile setPhoto={setPhoto} />
            </div>
           
            <div className="flex flex-col gap-3 rounded-sm bg-white py-5 px-3 shadow-md">
              <p className="font-sans text-sm ">
                Observer Name:{" "}
                <span className="font-semibold capitalize">
                  {firstName} {lastName}
                </span>
              </p>
            </div>
            <div className="flex flex-col gap-3 rounded-sm bg-white py-5 px-3 shadow-md">
              <p className="font-sans text-sm ">
                Date Reported::{" "}
                <span className="font-semibold capitalize">
                  {moment().format("MMMM Do YYYY, h:mm:ss A")}
                </span>
              </p>
            </div>
            <div className="flex justify-end">
              {/* <div className="w-36">
                <Button>Recommendation</Button>
              </div> */}
              <div className="w-32">
                <Button>Submit</Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
