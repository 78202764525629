/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikControl from "./Forms/FormikControl";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "./Button";
import { useLoginMutation } from "../features/auth/authApi";
import Alert from "./Alert/Alert";

export default function LoginForm() {
  const [login, { data, isSuccess, error }] = useLoginMutation();
  const initialValues = {
    employeeId: "",
    password: "",
  };

  const validationSchema = Yup.object({
    employeeId: Yup.string(),
    password: Yup.string(),
  });

  const onSubmit = (values) => {
    login(values);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && data?.status === "success") {
      navigate("/");
    }
  }, [data?.status, isSuccess]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className=" mt-10 flex flex-col gap-5 rounded-[10px] border border-black/20 p-5 shadow-lg  drop-shadow sm:mt-0 sm:w-[378px]">
            <h6 className="pb-10 text-center text-[32px] text-primary-50">
              Sign In
            </h6>
            {error && (
              <Alert
                type="error"
                message={error?.message || error?.data?.message}
              />
            )}
            <FormikControl
              control="input"
              name="employeeId"
              placeholder="Employee ID"
            />
            <FormikControl
              control="input"
              type="password"
              name="password"
              placeholder="password"
            />
            <div className="flex items-center justify-end">
              <NavLink
                to={"/forgot-password"}
                className="text-sm text-primary-50 hover:underline"
              >
                Forgot password?
              </NavLink>
            </div>
            <Button>Sign In</Button>
            <div className="flex items-center justify-center">
              <span>Or</span>
            </div>
            <div className="flex items-center justify-center">
              <p className="text-sm">
                Don’t have any account?
                <NavLink
                  to={"/sign-up"}
                  className="ml-3 text-primary-50 hover:underline"
                >
                  Sign Up
                </NavLink>
              </p>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
