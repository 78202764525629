import React from 'react'
import { useSelector } from 'react-redux';
import LoginLayout from '../components/LoginLayout';
import VerifyOtpForm from "../components/VerifyOtp";
import Onboard from './Onboard';

export default function Register() {
  
  const { verifyOtp } = useSelector((state) => state.auth);
  return (
    <LoginLayout>

      {verifyOtp === 0 && <Onboard />}
      {verifyOtp === 1 && <VerifyOtpForm />}
    </LoginLayout>
  );
}
