/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import ReportForm from "../components/ReportForm";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
export default function Home() {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.role === "Safety") {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="bg-[#FAFCFF]">
      <Header />
      <h2 className="py-5 text-center font-sans text-4xl font-semibold">
        Near Miss Reporting
      </h2>
      <ReportForm />
    </div>
  );
}
