import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../Alert/TextError";

export default function RadioButtons(props) {
  const { label, name, options, ...rest } = props || {};
  return (
    <>
      <div className="flex items-center justify-between">
        <label className="mb-2 block font-semibold  text-black/70">{label}</label>
        <div className="flex items-center justify-end flex-1">
          <Field name={name} {...rest}>
            {({ field }) => {
              return options.map((option) => {
                return (
                  <div className="flex items-center w-fit" key={option.key}>
                    <input
                      type="radio"
                      id={option.value}
                      {...field}
                      value={option.value}
                      checked={field.value === option.value}
                    />
                    <label
                      htmlFor={option.value}
                      className="pl-2 pr-4 capitalize w-[200px]"
                    >
                      {option.key}
                    </label>
                  </div>
                );
              });
            }}
          </Field>
        </div>
      </div>
      <ErrorMessage name={name} component={TextError} />
    </>
  );
}
