import React from "react";
import LoginForm from "../components/LoginForm";
import LoginLayout from "../components/LoginLayout";

export default function Login() {

  return (
    <LoginLayout>
          <LoginForm  />
    </LoginLayout>
  );
}
