import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikControl from "./Forms/FormikControl";
import { NavLink } from "react-router-dom";
import Button from "./Button";
import { useForgotPasswordMutation } from "../features/auth/authApi";
import Alert from "./Alert/Alert";

export default function GetCodeFrom() {
  const [forgotPassword, { data, error }] = useForgotPasswordMutation();
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
  });

  const onSubmit = (values) => {
    console.log("Form data", values);
    forgotPassword(values);
  };

  console.log(data, error);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className="  flex sm:w-[378px] mt-10 flex-col gap-4 rounded-[10px] border border-black/20 p-5  shadow-lg drop-shadow sm:mt-0">
            <h6 className="pb-10 text-center text-[32px] text-primary-50">
              Forgot Password
            </h6>

            {error?.status === 404 && (
              <Alert type="error" message={error?.data?.message} />
            )}
            {data?.status === "success" && (
              <Alert type="success" message={data?.message} />
            )}
            <FormikControl
              control="input"
              type="email"
              name="email"
              placeholder="Email"
            />

            <Button>Send</Button>
            <div className="flex items-center justify-center">
              <span>Or</span>
            </div>
            <div className="flex items-center justify-center">
              <p className="text-sm">
                Have another account?
                <NavLink
                  to={"/login"}
                  className="ml-3 text-primary-50 hover:underline"
                >
                  Sign In
                </NavLink>
              </p>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
