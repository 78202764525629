import React from "react";
import SelectOption from "react-select";
import { Field, ErrorMessage } from "formik";
import TextError from "../Alert/TextError";

export default function Select(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className="">
      <div className="">
        <label
          htmlFor={name}
          className="text-gray-900 mb-2 block text-base font-bold"
        >
          {label}
        </label>
        <Field name={name}>
          {({ form, field }) => {
            const { setFieldValue } = form;
            const { value } = field;
            return (
              <SelectOption
                options={options}
                getOptionLabel={(option) => option.label}
                value={value}
                id={name}
                {...field}
                {...rest}
                onChange={(list, selected) => setFieldValue(name, list)}
              />
            );
          }}
        </Field>
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
