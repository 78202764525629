import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../features/auth/authApi";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikControl from "./Forms/FormikControl";
import Alert from "./Alert/Alert";
import Button from "./Button";

export default function ResetPasswordForm() {
  const { verifyToken } = useParams();
  const [resetPassword, { data, error }] = useResetPasswordMutation();

  const initialValues = {
    password: "",
    passwordConfirm: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required().min(8),
    passwordConfirm: Yup.string().required().min(8),
  });

  const onSubmit = (values) => {
    resetPassword({ ...values, verifyToken });
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.status === "success") {
      navigate("/login");
    }
  }, [data?.status, navigate]);

  console.log(verifyToken);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className="  mt-10 flex flex-col gap-5 rounded-[10px] border border-black/20 p-5 shadow-lg  drop-shadow sm:mt-0 sm:w-[378px]">
            <h6 className="pb-10 text-center text-[32px] text-primary-50">
              Reset Password
            </h6>
            {error?.status === 400 && (
              <Alert type="error" message={error?.data?.message} />
            )}

            {data?.status === "success" && (
              <Alert
                type="success"
                message={"Password changed successfully!"}
              />
            )}
            <FormikControl
              control="input"
              type="password"
              name="password"
              placeholder="Password"
            />
            <FormikControl
              control="input"
              type="password"
              name="passwordConfirm"
              placeholder="passwordConfirm"
            />

            <Button>Submit</Button>
            <div className="flex items-center justify-center">
              <span>Or</span>
            </div>
            <div className="flex items-center justify-center">
              <p className="text-sm">
                Have another account?
                <NavLink
                  to={"/login"}
                  className="ml-3 text-primary-50 hover:underline"
                >
                  Sign in
                </NavLink>
              </p>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
