// import { toast } from "react-toastify";
import { toast } from "react-toastify";
import { apiSlice } from "../api/apiSlice";
export const reportApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fileUpload: builder.mutation({
      query: (data) => ({
        url: "/upload",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const {
            data: { data },
          } = await queryFulfilled;

          console.log(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
    getReports: builder.query({
      query: () => ({
        url: "/reports?sort=-createdAt",
        method: "GET",
      }),
      providesTags: ["Reports"],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const {
            data: { data },
          } = await queryFulfilled;

          console.log(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
    getSingleReport: builder.query({
      query: (id) => ({
        url: `/reports/${id}`,
        method: "GET",
      }),
    }),

    deleteReport: builder.mutation({
      query: (id) => ({
        url: `/reports/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Reports"],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          if (data.status === "success") {
            toast.success("Delete Successfully!");
          }
          console.log(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
    report: builder.mutation({
      query: (data) => ({
        url: "/reports",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          if (data.status === "success") {
            toast.success("Reported Successfully!");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
          console.log(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
    feedback: builder.mutation({
      query: (data) => ({
        url: "/feedbacks",
        method: "POST",
        body: data,
        }),
        invalidatesTags: ["Reports"],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          if (data.status === "success") {
            toast.success("Reported Successfully!");
          }
          console.log(data);
        } catch (err) {
          // do nothing
        }
      },
    }),
  }),
});

export const {
  useFileUploadMutation,
  useReportMutation,
  useGetReportsQuery,
  useGetSingleReportQuery,
  useDeleteReportMutation,
  useFeedbackMutation,
} = reportApi;
