/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikControl from "./Forms/FormikControl";
import Button from "./Button";
import greenCheck from "../assets/greencheck.png";

// import Alert from "./Alert/Alert";
import {
  departmentOptions,
  potentialIncidentOptions,
  typeConcernOptions,
  typeOfNearMissOptions,
  workareaOptions,
} from "./formdata";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { useReportMutation } from "../features/report/reportApi";
import { UploadFile } from "./UploadFile";

const isHseViolatedOptions = [
  { key: "Yes", value: "yes" },
  { key: "No", value: "no" },
];
export default function ReportForm() {
  const [photo, setPhoto] = useState([]);
  const {
    user: { firstName, lastName },
  } = useSelector((state) => state.auth);
  const [report, { data }] = useReportMutation();
  const initialValues = {
    department: { value: "", label: "Select Department" },
    workArea: { value: "", label: "Select Work Area" },
    typeNearMiss: [],
    typeNearMissDescribe: "",
    typeConcern: [],
    typeConcernDescribe: "",
    potentialIncident: [],
    isHseViolated: "",
  };

  const validationSchema = Yup.object({
    department: Yup.object().required("is required!"), //required field
    workArea: Yup.object().required("is required!"), //required field
    dateTime: Yup.date().default(function () {
      return new Date();
    }), //required field
    typeNearMiss: Yup.array(), //required field
    typeNearMissDescribe: Yup.string().when(
      "typeNearMiss",
      (typeNearMiss, schema) => {
        const d = typeNearMiss?.find((item) => item == "other");
        if (d) {
          return schema; //required field
        }
        return schema;
      }
    ),
    typeConcern: Yup.array().required("is required!"), //required field
    typeConcernDescribe: Yup.string().when(
      "typeConcern",
      (typeConcern, schema) => {
        const d = typeConcern?.find((item) => item == "other");
        if (d) {
          return schema; //required field
        }
        return schema;
      }
    ),
    potentialIncident: Yup.array().required("is required!"), //required field
    isHseViolated: Yup.string().required("is required!"), //required field
  });

  const onSubmit = (values) => {

    report({
      ...values,
      workArea: values.workArea.value,
      department: values.department.value,
      isHseViolated: values.isHseViolated === "yes" ? true : false,
      observerName: firstName + " " + lastName,
      reportDate: Date.now(),
      photo,
    });
  };
  console.log(data);
  //   const navigate = useNavigate();

  //   useEffect(() => {
  //     if (isSuccess && data?.status === "success") {
  //       navigate("/");
  //     }
  //   }, [data?.status, isSuccess]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className=" container flex flex-col gap-5 py-10">
            {/* {error && (
              <Alert
                type="error"
                message={error?.message || error?.data?.message}
              />
            )} */}
            <FormikControl
              control="select"
              name="department"
              label="Department:"
              options={departmentOptions}
              placeholder="select"
            />
            <FormikControl
              control="select"
              name="workArea"
              label="Building/Work Area:"
              options={workareaOptions}
              placeholder="select"
            />
            <FormikControl
              control="dateTime"
              name="dateTime:"
              label="Date and Time of Observation:"
              placeholder="select"
            />

            <div className="rounded-sm bg-white py-5 px-3 shadow-md">
              <FormikControl
                control="checkboxgroup"
                label="Type Of Near Miss:"
                name="typeNearMiss"
                options={typeOfNearMissOptions}
              />
              {formik.values?.typeNearMiss?.includes("other") && (
                <FormikControl
                  control="textarea"
                  name="typeNearMissDescribe"
                  placeholder="Describe"
                />
              )}
            </div>

            <div className="rounded-sm bg-white py-5 px-3 shadow-md">
              <FormikControl
                control="checkboxgroup"
                label="Type of Concern:"
                name="typeConcern"
                options={typeConcernOptions}
              />
              {formik.values?.typeConcern?.includes("other") && (
                <FormikControl
                  control="textarea"
                  name="typeConcernDescribe"
                  placeholder="Describe"
                />
              )}
            </div>

            <div className="rounded-sm bg-white py-5 px-3 shadow-md">
              <FormikControl
                control="checkboxgroup"
                label="Describe the potential incident/hazard/concern and possible outcome:"
                name="potentialIncident"
                options={potentialIncidentOptions}
              />
            </div>

            <div className="flex flex-col gap-3 rounded-sm bg-white py-5 px-3 shadow-md">

              <FormikControl
                control="radio"
                label="Were HSE procedures violated?"
                name="isHseViolated"
                options={isHseViolatedOptions}
              />
            </div>

            <div className="flex flex-col gap-3 rounded-sm bg-white py-5 px-3 shadow-md">
              <div className="flex items-center gap-3">
                <h3>Photos of Work Area</h3>
              </div>
              <ul>
                {photo?.map((p) => (
                  <li key={p} className="flex items-center gap-2">
                    {" "}
                    <a
                      href={"https://nodeapi.apps-sos.com/" + p}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {p.split(".jepg")[0]}
                    </a>
                    <span className="">
                      <img src={greenCheck} alt="" />
                    </span>
                  </li>
                ))}
              </ul>
              <UploadFile setPhoto={setPhoto} />
            </div>
            <div className="flex flex-col gap-3 rounded-sm bg-white py-5 px-3 shadow-md">
              <p className="font-sans text-sm ">
                Observer Name:{" "}
                <span className="font-semibold capitalize">
                  {firstName} {lastName}
                </span>
              </p>
            </div>
            <div className="flex flex-col gap-3 rounded-sm bg-white py-5 px-3 shadow-md">
              <p className="font-sans text-sm ">
                Date Reported::{" "}
                <span className="font-semibold capitalize">
                  {moment().format("MMMM Do YYYY, h:mm:ss A")}
                </span>
              </p>
            </div>

            <div className="flex items-center justify-end">
              <div className="w-32">
                <Button>Submit</Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
