import React from "react";
import { MdError, MdCheckCircle } from "react-icons/md";

const Alert = ({ type, message }) => {
  const icon = type === "error" ? <MdError /> : <MdCheckCircle />;
  const bgColor = type === "error" ? "bg-warning" : "bg-success";
  const textColor = type === "error" ? "text-[#742a2a]" : "text-[#1B5E20]";

  return (
    <div
      className={`${bgColor} ${textColor} flex items-center justify-between p-4`}
      role="alert"
    >
      <span className="flex items-center">
        {icon}
        <span className="ml-2">{message}</span>
      </span>
    </div>
  );
};

export default Alert;
