import React, { useState } from "react";
import Button from "../components/Button";
import RegisterForm from "../components/RegisterForm";

export default function Onboard() {
  const [role, setRole] = useState("employee");
  const [show, setShow] = useState(true);
  const handleLink = (state) => {
    setShow(false);
    setRole(state);
  };
  return (
    <>
      {show ? (
        <div className="flex flex-col items-center">
          <h6 className="pb-16 text-[32px] text-primary-50">Sign Up</h6>
          <div className="flex w-[250px] flex-col gap-3">
            <Button onClick={() => handleLink("Employee")}>
              {" "}
              As a Employee
            </Button>
            <Button onClick={() => handleLink("Safety")}>
              {" "}
              As a Safety Member
            </Button>
          </div>
        </div>
      ) : (
        <RegisterForm role={role} />
      )}
    </>
  );
}
