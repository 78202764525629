import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikControl from "./Forms/FormikControl";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "./Button";
import { useVerifyOtpMutation } from "../features/auth/authApi";
import { useSelector } from "react-redux";
import Alert from "./Alert/Alert";

export default function VerifyOtp() {
  const { email } = useSelector((state) => state.auth);
  const [verifyOtp, { data, error }] = useVerifyOtpMutation();
  const initialValues = {
    verifyToken: "",
  };

  const validationSchema = Yup.object({
    verifyToken: Yup.string(),
  });

  const onSubmit = (values) => {
    verifyOtp({ ...values, email });
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.status === "success") {
      navigate("/login");
    }
  }, [data?.status, navigate]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className="  mt-10 flex flex-col gap-5 rounded-[10px] border border-black/20 p-5 shadow-lg  drop-shadow sm:mt-0 sm:w-[378px]">
            <h6 className="pb-10 text-center text-[32px] text-primary-50">
              Verify OTP
            </h6>
            {error?.status === 400 && (
              <Alert type="error" message={error?.data?.message} />
            )}

            {data?.status === "success" && (
              <Alert type="success" message={"OTP verify successfully!"} />
            )}
            <FormikControl
              control="input"
              type="number"
              name="verifyToken"
              placeholder="One Time Password"
            />

            <Button>Verify</Button>
            <div className="flex items-center justify-center">
              <span>Or</span>
            </div>
            <div className="flex items-center justify-center">
              <p className="text-sm">
                Have another account?
                <NavLink
                  to={"/login"}
                  className="ml-3 text-primary-50 hover:underline"
                >
                  Sign in
                </NavLink>
              </p>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
