import React from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import art from "../assets/Art.png";
import { userLoggedOut } from '../features/auth/authSlice';
import Button from './Button';

export default function Header() {
    const dispatch = useDispatch()
    const handleLogout = () => {
        localStorage.clear();
        dispatch(userLoggedOut());
    }
  return (
    <div className="gradient flex items-center justify-between px-5 sm:px-20">
      <Link to="/" className="">
        <img src={art} className="h-[83px] w-[98px]" alt="" />
      </Link>
      <div className="w-20">
        <Button onClick={handleLogout} type="button">
          Logout
        </Button>
      </div>
    </div>
  );
}
