import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikControl from "./Forms/FormikControl";
import { NavLink } from "react-router-dom";
import Button from "./Button";
import { useRegisterMutation } from "../features/auth/authApi";
import { useDispatch } from "react-redux";
import { verifyOtp } from "../features/auth/authSlice";
import Alert from "./Alert/Alert";

export default function RegisterForm({ role }) {
  const [register, { data, isSuccess, error }] = useRegisterMutation();
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    employeeId: "",
    phone: "",
    password: "",
    passwordConfirm: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
    employeeId: Yup.string().required(),
    phone: Yup.string().required(),
    password: Yup.string().min(8).required(),
    passwordConfirm: Yup.string().min(8).required(),
  });

  const onSubmit = (values) => {
    console.log("Form data", values);
    register({ ...values, role });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      if (isSuccess && data?.status === "success") {
        dispatch(verifyOtp(1));
      }
    }, 3000);
  }, [data?.status, dispatch, isSuccess]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div className="  flex sm:w-[378px] mt-10 sm:mt-0 flex-col gap-4 rounded-[10px] border border-black/20  p-5 shadow-lg drop-shadow">
            <h6 className="pb-6 text-center text-[32px] text-primary-50">
              Sign Up
            </h6>

            {error?.status === 400 && (
              <Alert type="error" message={error?.data?.message} />
            )}

            {data?.status === "success" && (
              <Alert type="success" message={"Registration successfully!"} />
            )}
            <p className="font-medium">
              User Type:{" "}
              <span className="font-bold text-primary-50">{role}</span>
            </p>
            <FormikControl
              control="input"
              type="text"
              name="firstName"
              placeholder="First Name"
            />
            <FormikControl
              control="input"
              type="text"
              name="lastName"
              placeholder="Last Name"
            />
            <FormikControl
              control="input"
              type="email"
              name="email"
              placeholder="Email Address"
            />
            <FormikControl
              control="input"
              type="text"
              name="employeeId"
              placeholder="Employee ID"
            />
            <FormikControl
              control="input"
              type="phone"
              name="phone"
              placeholder="Phone Number"
            />
            <FormikControl
              control="input"
              type="password"
              name="password"
              placeholder="Password"
            />
            <FormikControl
              control="input"
              type="password"
              name="passwordConfirm"
              placeholder="Repeat Password"
            />

            <Button>Sign Up</Button>
            <div className="flex items-center justify-center">
              <span>Or</span>
            </div>
            <div className="flex items-center justify-center">
              <p className="text-sm">
                Have another account?
                <NavLink
                  to={"/login"}
                  className="ml-3 text-primary-50 hover:underline"
                >
                  Sign in
                </NavLink>
              </p>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
