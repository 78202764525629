import React from "react";
// import { FiAlertOctagon } from 'react-icons/fi';

export default function TextError(props) {
  return (
    <span className="text-red-600 mt-2 flex items-center gap-2 text-sm font-bold capitalize">
        {/* <FiAlertOctagon /> */}
          {props.children}
    </span>
  );
}
