export const departmentOptions = [
  { value: "Finance & Accounts", label: "Finance & Accounts" },
  {
    value: "Human Resource and Administration",
    label: "Human Resource and Administration",
  },
  { value: "Procurement ", label: "Procurement " },
  { value: "Operation", label: "Operation" },
  { value: "Maintenance", label: "Maintenance" },
  { value: "General Manager", label: "General Manager" },
  { value: "Quality, Safety and HSE:", label: "Quality, Safety and HSE:" },
];
export const workareaOptions = [
  { value: "Admin", label: "Admin" },
  { value: "RO Building", label: "RO Building" },
  { value: "Pretreatment", label: "Pretreatment" },
  { value: "Post Treatment", label: "Post Treatment" },
  { value: "DAF A", label: "DAF A" },
  { value: "DAF B", label: "DAF B" },
  { value: "Gravity Filter A", label: "Gravity Filter A" },
  { value: "Gravity Filter B", label: "Gravity Filter B" },
  { value: "ETP", label: "ETP" },
  { value: "Chiller Plant", label: "Chiller Plant" },
  { value: "Main Substation", label: "Main Substation" },
  { value: "RO Substation", label: "RO Substation" },
  { value: "DAF Substation", label: "DAF Substation" },
  { value: "Warehouse", label: "Warehouse" },
  { value: "Chemical Warehouse", label: "Chemical Warehouse" },
  { value: "Wastewater Tank", label: "Wastewater Tank" },
  { value: "Security Room", label: "Security Room" },
  { value: "Parking Area", label: "Parking Area" },
  { value: "Brine Pit", label: "Brine Pit" }
];
export const typeOfNearMissOptions = [
  { value: "Near-Miss", label: "Near-Miss" },
  { value: "HSE Concern", label: "HSE Concern" },
  { value: "other", label: "Other" },
]
export const typeConcernOptions = [
  { value: "Unsafe Act", label: "Unsafe Act" },
  { value: "Unsafe Condition of Area", label: "Unsafe Condition of Area" },
  {
    value: "Unsafe Condition of Equipment",
    label: "Unsafe Condition of Equipment",
  },
  { value: "Unsafe Use of Equipment", label: "Unsafe Use of Equipment" },
  { value: "HSE Procedure Violation", label: "HSE Procedure Violation" },
  { value: "Human Error", label: "Human Error" },
  {
    value: "Lack of Training/HSE Awareness",
    label: "Lack of Training/HSE Awareness",
  },
  { value: "Lack of Communication", label: "Lack of Communication" },
  { value: "other", label: "Other (describe)" },
];

export const potentialIncidentOptions = [
  { value: "Hit By/Against Something", label: "Hit By/Against Something" },
  { value: "Caught between objects", label: "Caught between objects" },
  { value: "Falling/Slips/Trips", label: "Falling/Slips/Trips" },
  { value: "High/Low Temperature", label: "High/Low Temperature" },
  { value: "Electric Shock", label: "Electric Shock" },
  {
    value: "Breathing Hazardous Substances",
    label: "Breathing Hazardous Substances",
  },
  {
    value: "Touching Hazardous Substances",
    label: "Touching Hazardous Substances",
  },
  { value: "Asset Damage", label: "Asset Damage" },
  { value: "Catastrophic consequences", label: "Catastrophic consequences" },
  { value: "Legal consequences", label: "Legal consequences" },
];
