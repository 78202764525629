import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../Alert/TextError";

export default function CheckboxGroups(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div>
      <>
        <label className="text-gray-900 mb-2 block text-base font-bold">
          {label}
        </label>
        {options.map((option, id) => (
          <label key={id} id={option.label} className="flex py-2 items-center justify-between">
            <span>{option.label}</span>
            <div className="">
              <Field type="checkbox" name={name} value={option.value} {...rest} />
            </div>
          </label>
        ))}
      </>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
