import React from "react";

import GetCodeFrom from "../components/GetCodeFrom";
import LoginLayout from "../components/LoginLayout";

export default function ForgotPassword() {
  return (
    <LoginLayout>
      <GetCodeFrom />
    </LoginLayout>
  );
}
