import React from 'react'
// import Checkbox from './CheckBox';
import CheckboxGroups from './CheckboxGroups';
// import DatePicker from './DatePicker';
import DateTimePicker from './DateTimePicker';
// import File from './File';
import Input from './Input';
import RadioButtons from './RadioButtons';

import Select from './Select';
import Textarea from './Textarea';

export default function FormikControl(props) {
    const { control, ...rest } = props
    switch (control) {
        case "input": return <Input {...rest} />
        // case "file": return <File {...rest} />
        case "textarea": return <Textarea {...rest} />
        case "select": return <Select {...rest} />
        case "radio": return <RadioButtons {...rest} />
        // case "checkbox": return <Checkbox {...rest} />
        case "checkboxgroup": return <CheckboxGroups {...rest} />
        // case "date": return <DatePicker {...rest} />
        case "dateTime": return <DateTimePicker {...rest} />
        
        default: return null;
    }
}
