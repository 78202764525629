import React from "react";

export default function Button({ children,type="submit", onClick }) {
  return (
    <button
      onClick={onClick}
      type={type}
      className="flex w-full items-center justify-center rounded-lg bg-[#002A7B] py-2 font-sans font-semibold text-white "
    >
      {children}
    </button>
  );
}
