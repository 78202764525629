import React from "react";
import art from "../assets/Art.png";

export default function LoginLayout({ children }) {
  return (
    <div className="flex flex-wrap sm:flex-nowrap min-h-screen w-full">
      <div className="gradient flex w-full sm:w-1/2 sm:pb-0  justify-center items-center">
        <div className="">
          <div className="flex justify-center w-full">
            <img src={art} alt="" className="sm:w-auto sm:h-auto  w-40" />
          </div>
          <div className="text-white">
            <h3 className="font-bold text-2xl text-center">Welcome aboard my friend</h3>
            <h6 className="text-center text-sm">just a couple of clicks and we start</h6>
          </div>
        </div>
      </div>
      <div className="flex w-full sm:w-1/2 justify-center items-center">{children}</div>
    </div>
  );
}
