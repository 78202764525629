import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../Alert/TextError";


export default function Input(props) {
  const { label, name, ...rest } = props;
  return (
    <div className="">
      <div className=" ">
        {label && (
          <label
            htmlFor={name}
            className="text-gray-900 w-full mb-2 block text-base font-bold"
          >
            {label}
          </label>
        )}
        <Field
          id={name}
          name={name}
          {...rest}
          className="rounded-lg border border-black/20 py-2 px-6 text-black"
        />
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
