import { useFileUploadMutation } from "../features/report/reportApi";
import fileICon from "../assets/file.png";
export const UploadFile = ({ setPhoto }) => {
  const [fileUpload, { error }] = useFileUploadMutation();

  // upload file
  function handleChange(event) {
    const formData = new FormData();
    formData.append(`image`, event.target.files[0]);
    fileUpload(formData)
      .unwrap()
      .then((res) => setPhoto((val) => val.length < 4 && [...val, res?.data?.image]));
  }

  return (
    <>
      <div className="flex items-center gap-5">
        <h3 className="mb-2] font-medium text-[#051532]">Attachments:(max 10MB)</h3>

        <div className="">
          <label
            htmlFor="file-upload"
            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
          >
            <img src={fileICon} alt="" />
            <input
              id="file-upload"
              name="file-upload"
              onChange={handleChange}
              type="file"
              className="sr-only"
            />
          </label>
        </div>
      </div>
      {error && (
        <p className="font-semibold text-red-600">{error?.data?.message}</p>
      )}
    </>
  );
};
