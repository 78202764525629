import React from "react";
import LoginLayout from "../components/LoginLayout";
import ResetPasswordForm from "../components/ResetPasswordForm";

export default function ResetPassword() {
  return (
    <LoginLayout>
      <ResetPasswordForm />
    </LoginLayout>
  );
}
