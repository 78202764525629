import { Route, Routes } from "react-router-dom";
import useAuthCheck from "../hooks/useAuthCheck";
import Dashboard from "../pages/Dashboard";
import ForgotPassword from "../pages/ForgotPassword";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ReportDetails from "../pages/ReportDetails";
import ResetPassword from "../pages/ResetPassword";
import Private from "./Private";
import Public from "./Public";

const MainRoutes = () => {
  const authChecked = useAuthCheck();
  return !authChecked ? (
    <div className="">Checking Authentication.....</div>
  ) : (
    <Routes>
      <Route path="/" element={<Private />}>
        <Route index element={<Home />} />
        <Route path="/dashboard">
          <Route index element={<Dashboard />} />
          <Route path=":id" element={<ReportDetails />} />
        </Route>
      </Route>
      <Route path="/" element={<Public />}>
        <Route path="login" element={<Login />} />
        <Route path="sign-up" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password">
          <Route path=":verifyToken" element={<ResetPassword />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default MainRoutes;
