import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
// import Auth from '../layouts/Auth/Auth';

const Private = () => {
  const { user, token } = useSelector((state) => state.auth);
  return user.email && token ? <Outlet /> : <Navigate to={"/login"} />;
};

export default Private;
